.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* qqw */
* {
    box-sizing: border-box;
}
body {
    font-family: "Helvetica Neue", sans-serif;
    background-color: black;
}
.storage {
    width: 80%;
    margin: auto;
    position: relative;
}
.storage h1 a{
    font-size: 50px;
    text-decoration: none;
    color: tomato;
}
.storage h1 a:hover {
    opacity: 0.5;
}
.storage h2 span.version {
    font-weight: 200;
    color: darkgray
}

/*  */
.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.stream {
  position: relative;
  height: 80vh;
  width: 40vh;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
