
.btn {
  text-transform: uppercase;
  color: #fff;
  border: 2px solid #FFCD42;
  display: inline-block;
  padding: 15px;
  padding-right: 50px;
  padding-left: 50px;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  transition: all 0.3s ease;
  margin: 15px;
}
.btn:hover {
  background-color: #FFCD42;
  color: #060e33;
}

.timecodes {
  width: 40vh;
  /* display: inline-block; */
  padding: 10px;
  color: #FFCD42;
}