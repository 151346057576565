@font-face {
  font-family: "Mediator Narrow Web Light"; 
  src: url("../static/3011eef00e6e7eaf387e195b31727b93.eot"); 
  src: url("../static/3011eef00e6e7eaf387e195b31727b93.eot") format("embedded-opentype"), 
  url("../static/3011eef00e6e7eaf387e195b31727b93.woff2") format("woff2"), 
  url("../static/3011eef00e6e7eaf387e195b31727b93.woff") format("woff"), 
  url("../static/3011eef00e6e7eaf387e195b31727b93.ttf") format("truetype"), 
}

.Header {
    /* display: 'grid', */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    min-height: 64;
}

.Header a {
    font-family: 'Mediator Narrow Web Light';
    color: #FFCD42;
    font-size: 24px;
    text-decoration: none;
    margin-left: 10px;
    border-color: black;
}

.noselect {
    outline: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}